import React from 'react';

const TopSlide = () => {
  return (
    <div className="top-slide">
      <h2>Take Your Acquisitions To The Next Level</h2>
      <a href="/apply-now" className="top-cta-button">Apply Now</a>
    </div>
  );
};

export default TopSlide;

