import React from 'react';
import logo from '../images/logo.png';  // Import the image
const BottomSlide = () => {
    return (
      <div className="bottom-slide">
        <div className="left-box">
          <img src={logo} alt="Yes Financial Service" className="logo" />
          <p>
  <em>
    Lending products are subject to credit application and approval.<br /><br />
    Disclaimer: By providing my wireless phone number to Yes Financial Service, I agree and acknowledge that Yes Financial Service may send text messages to my wireless phone number for any purpose, including marketing purposes.<br /><br />
    ClearBridge Consultants LLC © 2024. All rights reserved.<br /><br />
    <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms and Conditions</a> and 
    <a href="/privacy-policy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>.
  </em>
</p>
        </div>
        <div className="right-box">
          <nav className="menu">
            <ul>
              <li>Home</li>
              <li>SBA Loans</li>
              <li>Business Acquisitions</li>
              <li>Real Estate Acquisition Loans</li>
              <li>SBA Loan Calculator</li>
              <li>About Yes Financial Service</li>
              <li>Our Team</li>
              <li>Careers</li>
              <li>Testimonials</li>
              <li>Blog</li>
            </ul>
          </nav>
          <div className="contact-info">
            <address>
            Contact Us<br /><br />
            101 Plaza Real South Suite 204
           <p></p>
            Boca Raton, FL 33432<br /><br />
             For Immediate Service Call:
           <div>
           <a href="tel:+19543710069">(954) 371-0069</a>
            </div>
            <br /><br />
            Email:  <a href="mailto:Operations@YesFinancialService.Com">Operations@YesFinancialService.Com</a>
            </address>
          </div>
        </div>
      </div>
    );
};
export default BottomSlide;