import React, { useEffect, useRef, useState } from 'react';
import '../css/style.css'; // Ensure correct path to CSS
import AboutTeam from '../images/about-team-placeholder-photo.jpg';
import arrowIcon from '../images/arrow_icon.svg'; // Make sure to adjust the path to your SVG file
import arrowIconleft from '../images/arrow_icon_left.svg';
import keyIcon from '../images/testimonials-key-icon.png'; // Adjust path to your icon image

const About = () => {
  const countUpRef1 = useRef(null);
  const countUpRef2 = useRef(null);
  const countUpRef3 = useRef(null);

  useEffect(() => {
    const countUp = (element, endValue) => {
      let startValue = 0;
      const duration = 2000; // Duration in milliseconds
      const increment = endValue / (duration / 100); // Increment value
      const interval = setInterval(() => {
        startValue += increment;
        if (startValue >= endValue) {
          clearInterval(interval);
          startValue = endValue;
        }
        element.innerText = Math.floor(startValue).toLocaleString();
      }, 100);
    };

    if (countUpRef1.current) countUp(countUpRef1.current, 25256);
    if (countUpRef2.current) countUp(countUpRef2.current, 5000);
    if (countUpRef3.current) countUp(countUpRef3.current, 240);
  }, []);

  const TestimonialBox = ({ text, name }) => {
    return (
      <div className="testimonial-box-custom">
        <div className="testimonial-content-custom">
          <p className="testimonial-text-custom">{text}</p>
        </div>
        <div className="testimonial-info-custom">
          <img src={keyIcon} className="testimonial-icon-custom" alt="Key Icon" />
          <p className="testimonial-name-custom">{name}</p>
        </div>
      </div>
    );
  };

  const TestimonialsSlide = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const testimonials = [
      {
        text:
          "I highly recommend Yes Financial for their exceptional assistance in securing the financing we needed. Their professionalism, responsiveness, and dedication made the entire process seamless and stress-free.",
        name: "Elizabeth",
      },
      {
        text:
          "I obtained an SBA loan thanks to YES FINANCIAL special thanks to Juan a Forgia who helped me and guided me from start to end. I am very grateful with Yes Financial team. Thanks to them I now have an SBA loan on my business which helped me grow in my industry.",
        name: "Gabriela",
      },
      {
        text:
          "Yes Financial is absolutely amazing. They had the right solution for my needs and everybody was just so supportive and available. We will definitely build a long and profitable business relationship.",
        name: "Gabriela",
      },
      // Add more testimonials as needed
    ];

    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };

    const isMobile = () => {
      return window.innerWidth <= 1024;
    };

    return (
      <div className="slide-container-custom">
        <h2>Real Experience From Real Customers</h2>

        <div className="carousel-custom">
          <img src={arrowIconleft} className="arrow left-arrow-custom" onClick={handlePrev} alt="Previous" />
          <div className="reviews-container-custom">
            {testimonials
              .slice(currentIndex, currentIndex + (isMobile() ? 1 : 3))
              .map((testimonial, index) => (
                <TestimonialBox key={index} text={testimonial.text} name={testimonial.name} />
              ))}
          </div>
          <img src={arrowIcon} className="arrow right-arrow-custom" onClick={handleNext} alt="Next" />
        </div>

        <div className="cta-button-container-custom">
          <CtaButton />
        </div>
      </div>
    );
  };

  const CtaButton = () => {
    return (
      <a href="/testimonials-page" className="cta-button-custom">
        Read All Testimonials
      </a>
    );
  };

  return (
    <div className="about-page">
      <section className="about-hero">
        <h1>About Yes Financial Service</h1>
        <p>
          Yes Financial Service, based in Boca Raton, offers expert guidance in SBA loans for individuals and businesses. Our dedicated team provides personalized plans and seamless execution for successful loan applications.
        </p>
      </section>
      <section className="about-content">
        <h2>About Yes Financial</h2>
        <p>
          Yes Financial Service provides assistance and elbow grease to help its clients achieve success. The company takes pride in its humble beginnings and its self-proclaimed duty to help protect unwary borrowers from high-cost and risky non-traditional lending practices. We spoke with Edward Fay, Co-Founder of Yes Financial Service, for his take.
        </p>
        <p>
          "Yes Financial Service started its mission of helping small businesses with access to capital in 2015 when its founders Edward Fay and Gerald Brauser opened an office in Brauser’s truck stop. They saw the need for trucking companies, who were often on the road, to be able to get financing for big repairs or to acquire equipment. Yes Financial Service eventually grew to other industries as they understood a common dilemma—business owners were often busy and the first place they would look for capital was their local bank which were typically the big banks with tight credit boxes. They identified the SBA program as an excellent tool for companies in need of growth capital. Yes Financial Service Team’s goal is to prequalify businesses for their unique needs, save them from predatory lenders, and strategically help companies grow with acquisitions."
        </p>
      </section>
      <section className="mission-vision-slide">
        <div className="box">
          <h2>Our Mission</h2>
          <p>Sint clita assueverit eu est, pri at facete putant. Ferri graece conclusionemque quo ne, te congue nostrum usu. Id qui praesent reformidans. Elit eripuit tincidunt cum et. Cu qui nostro labores, omnis graeci efficiantur eos ne traspt..</p>
        </div>
        <div className="box">
          <h2>Our Vision</h2>
          <p>Sint clita assueverit eu est, pri at facete putant. Ferri graece conclusionemque quo ne, te congue nostrum usu. Id qui praesent reformidans. Elit eripuit tincidunt cum et. Cu qui nostro labores, omnis graeci efficiantur eos ne traspt..</p>
        </div>
      </section>
      <section className="values-slide">
        <div className="top-left">
          <h2>Our Values</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
        </div>
        <div className="value-box top-right">
          <h2>Integrity</h2>
          <p>Sint clita assueverit eu est, pri at facete putant. Graece conclusion emque quo ne, te congue.</p>
        </div>
        <div className="value-box bottom-left">
          <h2>Openness</h2>
          <p>Sint clita assueverit eu est, pri at facete putant. Graece conclusion emque quo ne, te congue.</p>
        </div>
        <div className="value-box bottom-right">
          <h2>Simplicity</h2>
          <p>Sint clita assueverit eu est, pri at facete putant. Graece conclusion emque quo ne, te congue.</p>
        </div>
      </section>
      <section className="statistics-slide">
        <div className="stat-box">
          <h2 ref={countUpRef1}>0</h2>
          <p>Successful Loan Applications</p>
        </div>
        <div className="stat-box">
          <h2 ref={countUpRef2}>0</h2>
          <p>Businesses Supported</p>
        </div>
        <div className="stat-box">
          <h2 ref={countUpRef3}>0</h2>   
          <p>Expert Advisors</p>
        </div>
      </section>
      <section className="meet-our-team-slide">
        <h1 className="meet-our-team-header">Meet Our Team</h1>
        <div className="team-content">
          <div className="team-image">
            <img src={AboutTeam} className="team-photo" alt="Team" />
          </div>
          <div className="team-text">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            <a href="/about-us" className="learn-more-button">Learn More</a>
          </div>
        </div>
      </section>
      <TestimonialsSlide />
    </div>
  );
};

export default About;