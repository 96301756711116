import React, { useState } from 'react';
import '../css/style.css'; // Ensure this path is correct

const LoanCalculator = () => {
  const [amount, setAmount] = useState(150000);
  const [interestRate, setInterestRate] = useState(6.75);
  const [years, setYears] = useState(10);
  const [frequency, setFrequency] = useState('Monthly');
  const [monthlyPayment, setMonthlyPayment] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [totalInterest, setTotalInterest] = useState(null);

  const calculatePayment = (e) => {
    e.preventDefault();
    const principal = parseFloat(amount);

    // Adjust interest rate and number of payments based on frequency
    let paymentsPerYear, interest;
    switch (frequency) {
      case 'Monthly':
        paymentsPerYear = 12;
        break;
      case 'Quarterly':
        paymentsPerYear = 4;
        break;
      case 'Yearly':
        paymentsPerYear = 1;
        break;
      default:
        paymentsPerYear = 12;
    }
    interest = parseFloat(interestRate) / 100 / paymentsPerYear;
    const calculatedPayments = parseFloat(years) * paymentsPerYear;

    const x = Math.pow(1 + interest, calculatedPayments);
    const payment = (principal * x * interest) / (x - 1);

    if (isFinite(payment)) {
      const totalPayment = payment * calculatedPayments;
      const totalInterestPaid = totalPayment - principal;

      // Formatting the values with commas
      setMonthlyPayment(payment.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setTotalCost(totalPayment.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
      setTotalInterest(totalInterestPaid.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ","));
    } else {
      setMonthlyPayment("Invalid input");
    }
  };

  const formatFrequency = (freq) => {
    switch (freq) {
      case 'Monthly':
        return 'month';
      case 'Quarterly':
        return 'quarter';
      case 'Yearly':
        return 'year';
      default:
        return 'month';
    }
  };

  return (
    <div className="loan-calculator">
      <h2>SBA 7(a) Loan Calculator</h2>
      <p>
        <strong>SBA 7(a) program:</strong> 10 year term with monthly payments ranging 
        from 10.25-11.25% current interest rate (subject to change and subject to borrower's financials).
      </p>
      <p>
        The SBA also has a 25 year option available for purchases of commercial real estate where 
        the owner occupies 51% or more of the property.
      </p>
      <form onSubmit={calculatePayment}>
        <div className="form-group">
          <label>Loan Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Loan Term</label>
          <div className="input-group">
            <input
              type="number"
              value={years}
              onChange={(e) => setYears(e.target.value)}
            />
            <span>years</span>
          </div>
        </div>
        <div className="form-group">
          <label>
            Interest Rate <span className="tooltip">i</span>
          </label>
          <div className="input-group">
            <input
              type="number"
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
            />
            <span>%</span>
          </div>
        </div>
        <div className="form-group">
          <label>Repayment Frequency</label>
          <select value={frequency} onChange={(e) => setFrequency(e.target.value)}>
            <option value="Monthly">Monthly</option>
            <option value="Quarterly">Quarterly</option>
            <option value="Yearly">Yearly</option>
          </select>
        </div>
        <button type="submit">Calculate</button>
      </form>
      {monthlyPayment && (
        <div className="result">
          <h3>Payments</h3>
          <div className="result-item">
            <strong>${monthlyPayment}</strong>
            <span>per {formatFrequency(frequency)}</span>
          </div>
          <div className="result-item">
            <strong>${totalCost}</strong>
            <span>Total Cost of Mortgage</span>
          </div>
          <div className="result-item">
            <strong>${totalInterest}</strong>
            <span>Total Interest Paid</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoanCalculator;
