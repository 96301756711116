import React, { useState, useEffect } from 'react';
import { Input, Ripple, initMDB } from 'mdb-ui-kit';
import { Button, Container, Row, Col, Form, Modal } from 'react-bootstrap';
import axios from 'axios';
import ProgressBar from './ProgressBar';
import '../css/style.css';
import { useNavigate } from 'react-router-dom';

const FormComponent = () => {
  useEffect(() => {
    initMDB({ Input, Ripple });
  }, []);

  const [formData, setFormData] = useState({
    businessName: '',
    firstName: '',
    lastName: '',
    citizenshipStatus: '',
    financingNeeds: '',
    yearsInBusiness: '',
    income2023: '',
    profitOrLoss2023: '',
    income2022: '',
    profitOrLoss2022: '',
    income2021: '',
    profitOrLoss2021: '',
    creditScore: '',
    existingDebt: [],
    phoneNumber: '',
    emailAddress: '',
    preferredCommunication: '',
    ownershipPercentage: '',
    businessAddress: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    controllingInterest: '',
    bankruptcyHistory: [],
    federalLoanDefault: '',
    legalIssues: '',
    commercialPropertyInterest: '',
    criminalHistory: '',
    childSupportStatus: '',
    taxLiens: [],
    notes: '',
    referralSource: '',
    certification: '',
  });

  const [step, setStep] = useState(1);
  const [missingFields, setMissingFields] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const steps = [
    { title: 'Step 1' },
    { title: 'Step 2' },
    { title: 'Step 3' },
    { title: 'Step 4' },
    { title: 'Step 5' },
    { title: 'Step 6' },
    { title: 'Step 7' },
    { title: 'Step 8' },
  ];

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const navigate = useNavigate();

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData((prevFormData) => {
      const updatedValues = checked
        ? [...prevFormData[name], value]
        : prevFormData[name].filter((item) => item !== value);
      return {
        ...prevFormData,
        [name]: updatedValues,
      };
    });
  };

  const nextStep = () => {
    const stepFields = {
      1: ['businessName', 'firstName', 'lastName', 'citizenshipStatus'],
      2: ['financingNeeds', 'yearsInBusiness', 'income2023', 'profitOrLoss2023'],
      3: ['income2022', 'profitOrLoss2022', 'income2021', 'profitOrLoss2021'],
      4: ['creditScore', 'existingDebt', 'phoneNumber', 'emailAddress'],
      5: ['ownershipPercentage', 'businessAddress', 'postalCode', 'city', 'state', 'country'],
      6: ['controllingInterest', 'bankruptcyHistory', 'federalLoanDefault', 'legalIssues'],
      7: [],
      8: ['certification']
    };

    const currentStepFields = stepFields[step];
    const missingFields = currentStepFields.filter(field => {
      if (Array.isArray(formData[field])) {
        return formData[field].length === 0;
      }
      return !formData[field];
    });

    if (missingFields.length > 0) {
      setMissingFields(missingFields);
      return;
    }

    setMissingFields([]);
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requiredFields = [
      'businessName', 'firstName', 'lastName', 'citizenshipStatus',
      'financingNeeds', 'yearsInBusiness', 'income2023', 'profitOrLoss2023',
      'income2022', 'profitOrLoss2022', 'income2021', 'profitOrLoss2021',
      'creditScore', 'existingDebt', 'phoneNumber', 'emailAddress',
      'ownershipPercentage', 'businessAddress', 'postalCode', 'city', 'state',
      'country', 'controllingInterest', 'bankruptcyHistory', 'federalLoanDefault',
      'legalIssues', 'certification'
    ];

    const missingFields = requiredFields.filter(field => {
      if (Array.isArray(formData[field])) {
        return formData[field].length === 0;
      }
      return !formData[field];
    });

    if (missingFields.length > 0) {
      setMissingFields(missingFields);
      return;
    }

    const formDataWithDefaults = {
      ...formData,
      notes: formData.notes || 'N/A',
      referralSource: formData.referralSource || 'N/A',
    };

    try {
      const response = await axios.post('https://yfs-6a5b5862a5f4.herokuapp.com/api/submit-form', formDataWithDefaults);

      if (response.status === 200) {
        console.log('Form submitted successfully to Podio');
        setShowModal(true);
      } else {
        throw new Error('Form submission to Podio failed');
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    navigate('/about');
  };

  const renderStep = () => {
    const citizenshipOptions = [
      { value: 'US Citizen', label: 'US Citizen' },
      { value: 'Legal Permanent Resident', label: 'Legal Permanent Resident' },
      { value: 'None of the above', label: 'None of the above' },
    ];

    const profitOrLossOptions = [
      { value: 'Profit', label: 'Profit' },
      { value: 'Loss', label: 'Loss' },
    ];

    const existingDebtOptions = [
      { value: 'Line of Credit', label: 'Line of Credit' },
      { value: 'SBA', label: 'SBA' },
      { value: 'Merchant Cash Advance', label: 'Merchant Cash Advance' },
      { value: 'Credit Cards', label: 'Credit Cards' },
      { value: 'Term Loan', label: 'Term Loan' },
      { value: 'Commercial Loan with a balloon payment', label: 'Commercial Loan with a balloon payment' },
    ];

    const preferredCommunicationOptions = [
      { value: 'Email', label: 'Email' },
      { value: 'Phone', label: 'Phone' },
      { value: 'Text', label: 'Text' },
    ];

    const bankruptcyHistoryOptions = [
      { value: 'Bankruptcy - Business', label: 'Bankruptcy - Business' },
      { value: 'Bankruptcy - Personal', label: 'Bankruptcy - Personal' },
      { value: 'Foreclosure', label: 'Foreclosure' },
      { value: 'Other', label: 'Other' },
      { value: 'None', label: 'None' },
    ];

    const taxLiensOptions = [
      { value: 'Open Tax Lien 1', label: 'Open Tax Lien 1' },
      { value: 'Open Tax Lien 2', label: 'Open Tax Lien 2' },
    ];

    const renderCheckboxGroup = (options, name) => (
      <div className={`checkbox-group ${missingFields.includes(name) ? 'is-invalid' : ''}`}>
        {options.map((option) => (
          <label key={option.value} className={`checkbox-label ${formData[name].includes(option.value) ? 'selected' : ''}`}>
            <input
              type="checkbox"
              name={name}
              value={option.value}
              checked={formData[name].includes(option.value)}
              onChange={handleCheckboxChange}
            />
            {option.label}
          </label>
        ))}
      </div>
    );

    const getClassForField = (field) => {
      return missingFields.includes(field) ? 'form-control is-invalid' : 'form-control';
    };

    switch (step) {
      case 1:
        return (
          <div className="form-content">
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Business name *</Form.Label>
              <Form.Control
                type="text"
                id="businessName"
                className={getClassForField('businessName')}
                value={formData.businessName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">First Name *</Form.Label>
              <Form.Control
                type="text"
                id="firstName"
                className={getClassForField('firstName')}
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Last Name *</Form.Label>
              <Form.Control
                type="text"
                id="lastName"
                className={getClassForField('lastName')}
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Citizenship Status (US Citizen, Legal Permanent Resident, or None of the above) *</Form.Label>
              <Form.Control
                as="select"
                id="citizenshipStatus"
                className={getClassForField('citizenshipStatus')}
                value={formData.citizenshipStatus}
                onChange={handleChange}
                required
              >
                <option value="">Please select an option</option>
                {citizenshipOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <p className="disclaimer text-danger">* indicates a required field!</p>
          </div>
        );
      case 2:
        return (
          <div className="form-content">
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Financing Needs (Amount and Purpose) *</Form.Label>
              <Form.Control
                type="text"
                id="financingNeeds"
                className={getClassForField('financingNeeds')}
                value={formData.financingNeeds}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Years in Business *</Form.Label>
              <Form.Control
                type="number"
                id="yearsInBusiness"
                className={getClassForField('yearsInBusiness')}
                value={formData.yearsInBusiness}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">2023 Gross Income (Before Expenses) in USD *</Form.Label>
              <Form.Control
                type="number"
                id="income2023"
                className={getClassForField('income2023')}
                value={formData.income2023}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">2023 Financial Result (Profit or Loss) *</Form.Label>
              <Form.Control
                as="select"
                id="profitOrLoss2023"
                className={getClassForField('profitOrLoss2023')}
                value={formData.profitOrLoss2023}
                onChange={handleChange}
                required
              >
                <option value="">Please select an option</option>
                {profitOrLossOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        );
      case 3:
        return (
          <div className="form-content">
            <Form.Group className="mb-4">
              <Form.Label className="form-label">2022 Gross Income (Before Expenses) in USD *</Form.Label>
              <Form.Control
                type="number"
                id="income2022"
                className={getClassForField('income2022')}
                value={formData.income2022}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">2022 Financial Result (Profit or Loss) *</Form.Label>
              <Form.Control
                as="select"
                id="profitOrLoss2022"
                className={getClassForField('profitOrLoss2022')}
                value={formData.profitOrLoss2022}
                onChange={handleChange}
                required
              >
                <option value="">Please select an option</option>
                {profitOrLossOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">2021 Gross Income (Before Expenses) in USD *</Form.Label>
              <Form.Control
                type="number"
                id="income2021"
                className={getClassForField('income2021')}
                value={formData.income2021}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">2021 Financial Result (Profit or Loss) *</Form.Label>
              <Form.Control
                as="select"
                id="profitOrLoss2021"
                className={getClassForField('profitOrLoss2021')}
                value={formData.profitOrLoss2021}
                onChange={handleChange}
                required
              >
                <option value="">Please select an option</option>
                {profitOrLossOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        );
      case 4:
        return (
          <div className="form-content">
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Approximate Credit Score *</Form.Label>
              <Form.Control
                type="number"
                id="creditScore"
                className={getClassForField('creditScore')}
                value={formData.creditScore}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Existing Debt (Select all that apply) *</Form.Label>
              {renderCheckboxGroup(existingDebtOptions, 'existingDebt')}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Phone Number *</Form.Label>
              <Form.Control
                type="text"
                id="phoneNumber"
                className={getClassForField('phoneNumber')}
                value={formData.phoneNumber}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Email Address *</Form.Label>
              <Form.Control
                type="email"
                id="emailAddress"
                className={getClassForField('emailAddress')}
                value={formData.emailAddress}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Preferred method of communication (Optional)</Form.Label>
              <Form.Control
                as="select"
                id="preferredCommunication"
                className={getClassForField('preferredCommunication')}
                value={formData.preferredCommunication}
                onChange={handleChange}
              >
                <option value="">Please select an option</option>
                {preferredCommunicationOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        );
      case 5:
        return (
          <div className="form-content">
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Ownership Percentage *</Form.Label>
              <Form.Control
                type="number"
                id="ownershipPercentage"
                className={getClassForField('ownershipPercentage')}
                value={formData.ownershipPercentage}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Business address *</Form.Label>
              <Form.Control
                type="text"
                id="businessAddress"
                className={getClassForField('businessAddress')}
                value={formData.businessAddress}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Postal Code *</Form.Label>
              <Form.Control
                type="text"
                id="postalCode"
                className={getClassForField('postalCode')}
                value={formData.postalCode}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">City *</Form.Label>
              <Form.Control
                type="text"
                id="city"
                className={getClassForField('city')}
                value={formData.city}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">State *</Form.Label>
              <Form.Control
                type="text"
                id="state"
                className={getClassForField('state')}
                value={formData.state}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Country *</Form.Label>
              <Form.Control
                type="text"
                id="country"
                className={getClassForField('country')}
                value={formData.country}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </div>
        );
      case 6:
        return (
          <div className="form-content">
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Controlling Interest in Other Businesses (Yes or No) *</Form.Label>
              <Form.Control
                as="select"
                id="controllingInterest"
                className={getClassForField('controllingInterest')}
                value={formData.controllingInterest}
                onChange={handleChange}
                required
              >
                <option value="">Please select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Bankruptcy or Foreclosure History (Select all that apply) *</Form.Label>
              {renderCheckboxGroup(bankruptcyHistoryOptions, 'bankruptcyHistory')}
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Default on a Federal Loan (Yes or No) *</Form.Label>
              <Form.Control
                as="select"
                id="federalLoanDefault"
                className={getClassForField('federalLoanDefault')}
                value={formData.federalLoanDefault}
                onChange={handleChange}
                required
              >
                <option value="">Please select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Involvement in Legal Action or Unresolved Judgements (Yes or No) *</Form.Label>
              <Form.Control
                as="select"
                id="legalIssues"
                className={getClassForField('legalIssues')}
                value={formData.legalIssues}
                onChange={handleChange}
                required
              >
                <option value="">Please select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
          </div>
        );
      case 7:
        return (
          <div className="form-content">
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Are you interested in buying a commercial property? (Optional)</Form.Label>
              <Form.Control
                as="select"
                id="commercialPropertyInterest"
                className="form-control"
                value={formData.commercialPropertyInterest}
                onChange={handleChange}
              >
                <option value="">Please select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Have any owners ever been arrested for or plead guilty to a felony or misdemeanor? (Optional)</Form.Label>
              <Form.Control
                as="select"
                id="criminalHistory"
                className="form-control"
                value={formData.criminalHistory}
                onChange={handleChange}
              >
                <option value="">Please select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Are any owners behind on child support? (Optional)</Form.Label>
              <Form.Control
                as="select"
                id="childSupportStatus"
                className="form-control"
                value={formData.childSupportStatus}
                onChange={handleChange}
              >
                <option value="">Please select an option</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Do you have any open tax liens or unpaid taxes? Select all that apply (Optional)</Form.Label>
              {renderCheckboxGroup(taxLiensOptions, 'taxLiens')}
            </Form.Group>
          </div>
        );
      case 8:
        return (
          <div className="form-content">
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Other notes that would be helpful for us (Optional)</Form.Label>
              <Form.Control
                type="text"
                id="notes"
                className="form-control"
                value={formData.notes}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">How did you hear about us? (Optional)</Form.Label>
              <Form.Control
                type="text"
                id="referralSource"
                className="form-control"
                value={formData.referralSource}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label className="form-label">Certification (Type your full name to certify the information is correct) *</Form.Label>
              <Form.Control
                type="text"
                id="certification"
                className={getClassForField('certification')}
                value={formData.certification}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Container className="form-container">
      <Row>
        <Col>
          <ProgressBar steps={steps} currentStep={step - 1} />
        </Col>
      </Row>
      <Row className="form-content-container">
        <Col>
          <form>
            {renderStep()}
          </form>
        </Col>
      </Row>
      <Row className="form-footer">
        <Col>
          {step > 1 && (
            <Button onClick={prevStep} className="btn back-btn">Back</Button>
          )}
        </Col>
        <Col className="text-right">
          {step < steps.length ? (
            <Button onClick={nextStep} className="btn next-btn">Next</Button>
          ) : (
            <Button onClick={handleSubmit} className="btn apply-now-btn next-btn">Submit</Button>
          )}
        </Col>
      </Row>

      <Modal 
  show={showModal} 
  onHide={handleCloseModal} 
  centered  // This is a shorthand in react-bootstrap for modal-dialog-centered
>
  <Modal.Header closeButton>
    <Modal.Title>Submission Received</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>Thank you for completing the form. Your submission has been received, and we will review it shortly. If you have any further questions, feel free to contact us.</p>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="primary" onClick={handleCloseModal}>
      OK
    </Button>
  </Modal.Footer>
</Modal>

    </Container>
  );
};

export default FormComponent;
