import React from 'react';
import FormComponent from './FormComponent'; // Import the FormComponent

function Apply() {
  return (
    <div>
    <br></br>
      <FormComponent /> {/* Add FormComponent to the JSX */}
    </div>
  );
}

export default Apply;