import React from 'react';
import Product from './Product';

function Shop() {
  return (
    <div>
      <Product />
    </div>
  );
}

export default Shop;
