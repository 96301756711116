import React from 'react';
import Hero from './Hero';
import Product from './Product';
import WhyChooseUs from './WhyChooseUs';
import WeHelp from './WeHelp';
import StatsSlide from './StatsSlide';
import Testi from  './Testi';
import FormComponent from './FormComponent';

function Home() {
  return (
    <div>
      <Hero />

      <Product />

      <WhyChooseUs />

      <WeHelp />

      <StatsSlide />

      <Testi />

      
    </div>
  );
}

export default Home;
