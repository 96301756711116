import React, { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const StatsSlide = () => {
  const [startAnimation, setStartAnimation] = useState(false);
  const [counted1, setCounted1] = useState(false);
  const [counted2, setCounted2] = useState(false);
  const [counted3, setCounted3] = useState(false);

  const { ref: ref1, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const { ref: ref3, inView: inView3 } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    if (inView1 && !counted1) {
      setCounted1(true);
      setStartAnimation(true);
    }
    if (inView2 && !counted2) {
      setCounted2(true);
      setStartAnimation(true);
    }
    if (inView3 && !counted3) {
      setCounted3(true);
      setStartAnimation(true);
    }
  }, [inView1, inView2, inView3, counted1, counted2, counted3]);

  return (
    <div className="slide stats-slide" style={{ backgroundColor: '#f7f8f8', paddingTop: '100px', paddingBottom: '100px' }}>
      <div className="container">
        <div className="row">
          {/* Box 1 */}
          <div className="col-lg-3 col-md-6">
            <div className="box" ref={ref1}>
              {counted1 && (
                <CountUp start={0} end={25256} duration={2.5} separator="," className="number" />
              )}
              <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>

          {/* Box 2 */}
          <div className="col-lg-3 col-md-6">
            <div className="box" ref={ref2}>
              {counted2 && (
                <CountUp start={0} end={5000} duration={2.5} separator="," className="number" />
              )}
              <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>

          {/* Box 3 */}
          <div className="col-lg-3 col-md-6">
            <div className="box" ref={ref3}>
              {counted3 && (
                <CountUp start={0} end={240} duration={2.5} separator="," className="number" />
              )}
              <p className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatsSlide;