import React, { useState } from 'react';
import '../css/style.css'; // Ensure correct path to CSS
import arrowIcon from '../images/arrow_icon.svg'; // Adjust path to your SVG file
import arrowIconleft from '../images/arrow_icon_left.svg';
import keyIcon from '../images/testimonials-key-icon.png'; // Adjust path to your icon image

const RealEstate = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      text: "I highly recommend Yes Financial for their exceptional assistance in securing the financing we needed. Their professionalism, responsiveness, and dedication made the entire process seamless and stress-free.",
      name: "Elizabeth",
    },
    {
      text: "I obtained an SBA loan thanks to YES FINANCIAL special thanks to Juan a Forgia who helped me and guided me from start to end. I am very grateful with Yes Financial team. Thanks to them I now have an SBA loan on my business which helped me grow in my industry.",
      name: "Gabriela",
    },
    {
      text: "Yes Financial is absolutely amazing. They had the right solution for my needs and everybody was just so supportive and available. We will definitely build a long and profitable business relationship.",
      name: "Gabriela",
    },
    // Add more testimonials as needed
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  // Function to detect mobile screen size
  const isMobile = () => {
    return window.innerWidth <= 1024;
  };

  return (
    <>
      {/* Hero Section */}
      <section className="real-estate-hero">
        <div className="container">
          <div className="hero-content">
            <h1>Commercial Real Estate</h1>
            <p>We Will Help You Unlock the Door to Success in Commercial Real Estate.</p>
          </div>
        </div>
      </section>

      {/* Detailed Content Section */}
      <div className="real-estate-content-section">
        <header className="real-estate-content-header">
          <h1>What We Can Do For You</h1>
        </header>
        <section className="real-estate-content-body">
          <p>
            Looking to finance or refinance commercial real estate for your business? Our team can guide
            you through the process with comprehensive services designed to meet your needs. We assist
            with finding and evaluating properties that meet SBA requirements, ensuring you find the perfect
            location for your business. Additionally, our loan consultation service offers expert advice to help
            you secure the ideal SBA 7(a) loan, tailored to your financial situation and property goals. Let us
            simplify the path to your commercial real estate success.
          </p>
          <div className="real-estate-info-boxes">
            <div className="real-estate-info-box">
              <h2>SBA 7(a) Loans for Business Growth</h2>
              <p>
                Leverage SBA loans to purchase or refinance commercial real estate for your business. Whether you're buying a business property or upgrading your current space, our SBA 7(a) loans are perfect for your commercial real estate acquisition needs.
              </p>
            </div>
            <div className="real-estate-info-box">
              <h2>Comprehensive Property Search Assistance</h2>
              <p>
                We provide expert assistance in locating and evaluating commercial real estate properties that meet SBA requirements. Let us guide you through the process of finding the perfect space for your business.
              </p>
            </div>
            <div className="real-estate-info-box">
              <h2>Personalized Loan Consultation Services</h2>
              <p>
                Our personalized loan consultation services help you review your business finances and property details to determine the optimal SBA 7(a) loan amount and terms. Get expert advice to secure the best real estate acquisition loans for your business growth.
              </p>
            </div>
          </div>
        </section>
      </div>

      {/* Additional Section */}
      <section className="sba-values-slide">
        <div className="sba-values-top-left">
          <h2>SBA Loans</h2>
          <p>Unlock your growth potential with Yes Financial Service. Whether you're purchasing or renovating commercial real estate, acquiring a franchise, or expanding your facilities, our competitive SBA 7(a) loans offer flexible financing solutions tailored to your needs. Transform your vision into reality today!</p>
        </div>
        <div className="sba-values-box sba-values-top-right">
          <h2>Flexible Financing Solutions</h2>
          <p>At Yes Financial Service, our SBA Loans cover a range of needs including purchases of owner-occupied commercial real estate, business acquisitions, franchise investments, leasehold improvements, working capital, debt refinancing, and equipment purchases.</p>
        </div>
        <div className="sba-values-box sba-values-bottom-left">
          <h2>Revitalize Your Space</h2>
          <p>Transform an existing property into your ideal workspace with Yes Financial Service. Our SBA 7(a) loans offer competitive rates for acquiring and renovating commercial buildings, helping you set up operations efficiently.</p>
        </div>
        <div className="sba-values-box sba-values-bottom-right">
          <h2>Expand with Ease</h2>
          <p>Growing your business? Yes Financial Service provides SBA 7(a) loans to finance expansions, allowing you to add on to your current facilities and accommodate your evolving needs.</p>
        </div>
      </section>

      {/* Testimonials Section */}
      <div className="slide-container-custom">
        <h2>Don’t Take Our Word For It.</h2>

        <div className="carousel-custom">
          <img src={arrowIconleft} className="arrow left-arrow-custom" onClick={handlePrev} alt="Previous" />
          <div className="reviews-container-custom">
            {testimonials
              .slice(currentIndex, currentIndex + (isMobile() ? 1 : 3))
              .map((testimonial, index) => (
                <div className="testimonial-box-custom" key={index}>
                  <div className="testimonial-content-custom">
                    <p className="testimonial-text-custom">{testimonial.text}</p>
                  </div>
                  <div className="testimonial-info-custom">
                    <img src={keyIcon} className="testimonial-icon-custom" alt="Key Icon" />
                    <p className="testimonial-name-custom">{testimonial.name}</p>
                  </div>
                </div>
              ))}
          </div>
          <img src={arrowIcon} className="arrow right-arrow-custom" onClick={handleNext} alt="Next" />
        </div>

        <div className="cta-button-container-custom">
          <a href="/testimonials-page" className="cta-button-custom">Read All Testimonials</a>
        </div>
      </div>
    </>
  );
};

export default RealEstate;