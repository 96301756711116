import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/style.css'; // Ensure this path is correct
import heroImage from '../images/hero-image.png'; // Import the image

const HeroSection = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5">
            <div className="intro-excerpt">
              <h1>
                <span className="d-block">LOREM IPSUM DOLOR SIT AMET SU SCRIPT</span>
              </h1>
              <p className="mb-4">Sint clita asverit eu est, pri at facete iut.</p>
              <p>
                <Link to="/apply" className="apply-now-btn">APPLY NOW</Link>
              </p>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="hero-img-wrap">
              <img src={heroImage} className="img-fluid" alt="Hero Image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
