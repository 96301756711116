import React from 'react';
import keyIcon from '../images/key_icon.png';
import bagIcon from '../images/bag.svg';
import supportIcon from '../images/support.svg';
import returnIcon from '../images/return.svg';
import whyChooseUsImg from '../images/why-choose-us-img.jpg';
import arrowIcon from '../images/arrow_icon.svg'; // Assuming this is your arrow icon SVG

const WhyChooseUs = () => {
    return (
      <div className="why-choose-us-section">
        <div className="container">
          <div className="row align-items-center">
            {/* Left Section with Boxes */}
            <div className="col-lg-6 order-lg-1 order-2">
              <div className="choose-boxes">
                {/* Box 1 */}
                <div className="choose-box">
                  <h3>SBA Loans</h3>
                  <p>Sint clita assueverit eu est, pri at facete putant. Graece conclusion emque quo ne, te congue.</p>
                  <button className="arrow-button">
                    <img src={arrowIcon} alt="Arrow Icon" className="arrow-icon" />
                  </button>
                </div>
  
                {/* Box 2 */}
                <div className="choose-box">
                  <h3>Real Estate Acquisition Loans</h3>
                  <p>Sint clita assueverit eu est, pri at facete putant. Graece conclusion emque quo ne, te congue.</p>
                  <button className="arrow-button">
                    <img src={arrowIcon} alt="Arrow Icon" className="arrow-icon" />
                  </button>
                </div>
  
                {/* Box 3 */}
                <div className="choose-box">
                  <h3>Business Acquisitions</h3>
                  <p>Sint clita assueverit eu est, pri at facete putant. Graece conclusion emque quo ne, te congue.</p>
                  <button className="arrow-button">
                    <img src={arrowIcon} alt="Arrow Icon" className="arrow-icon" />
                  </button>
                </div>
              </div>
            </div>
  
            {/* Right Section with Bullet Points */}
            <div className="col-lg-6 order-lg-2 order-1 choose-content">
              <div className="choose-content">
                <h2>What We Can Do For Your Business</h2>
                <p>
                  Lorem ipsum dolor sit amet, suas scripta te sea, vitae consulatu, denique euripidis pri. Alii movet perfecto no quo.
                  Mel equidem, sed praesent persequeris eu.
                </p>
  
                <ul className="bullet-points">
                  <li className="choose-bullet-point">
                    <img src={keyIcon} alt="Key Icon" className="key-icon" /> Maximum SBA 7(a) loan amounts of $5 million
                  </li>
                  
                  <li className="choose-bullet-point">
                    <img src={keyIcon} alt="Key Icon" className="key-icon" /> Loan terms from 10 to 25 years
                  </li>
                  
                  <li className="choose-bullet-point">
                    <img src={keyIcon} alt="Key Icon" className="key-icon" /> Average processing time 30-45 days
                  </li>
                  
                  <li className="choose-bullet-point">
                    <img src={keyIcon} alt="Key Icon" className="key-icon" /> SBA loan process education and assistance
                  </li>
  
                  <li className="choose-bullet-point">
                    <img src={keyIcon} alt="Key Icon" className="key-icon" /> Post-application assistance
                  </li>
  
                  <li className="choose-bullet-point">
                    <img src={keyIcon} alt="Key Icon" className="key-icon" /> Personalized custom services
                  </li>
                  
                  <li className="choose-bullet-point">
                    <img src={keyIcon} alt="Key Icon" className="key-icon" /> Protection from predatory lending vulnerabilities
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default WhyChooseUs;