import React from 'react';
import Map from './Map';
import '../css/style.css'; // Make sure to import your CSS file
import '../css/bootstrap.min.css';
import '../css/tiny-slider.css';

function UsdaMap() {
  return (
    <div>
        <br></br>
        <br></br>
      <div className="map-container">
        <Map />
        <div className="map-info">
          USDA Rural Development Program Eligibility Map (2020 Census) – Brought to You by Yes Financial Service. Updated and Launched July 2024.
        </div>
      </div>
      <footer className="footer">
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </footer>
    </div>
  );
}

export default UsdaMap;
