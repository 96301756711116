import React from 'react';

const ProductSection = () => {
  // Import images dynamically
  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  };

  const images = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

  return (
    <div className="product-section">
      <div className="container">
        <div className="row">
          {/* Column 1 */}
          <div className="col-md-12 col-lg-3 mb-5 mb-lg-0"></div>

          {/* Column 2 */}
          <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
            <div className="product-item">
              <div className="product-thumbnail">
                <img src={images['boca-logo.png']} className="img-fluid product-thumbnail" alt="Boca Logo" />
              </div>
            </div>
          </div>

          {/* Column 3 */}
          <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
            <div className="product-item">
              <div className="product-thumbnail">
                <img src={images['trusted-logo.png']} className="img-fluid product-thumbnail" alt="Trusted Logo" />
              </div>
            </div>
          </div>

          {/* Column 4 */}
          <div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
            <div className="product-item">
              <div className="product-thumbnail">
                <img src={images['changing-logo.png']} className="img-fluid product-thumbnail" alt="Changing Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductSection;
