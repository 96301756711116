import React, { useState } from 'react';
import arrowIcon from '../images/arrow_icon.svg'; // Make sure to adjust the path to your SVG file
import arrowIconleft from '../images/arrow_icon_left.svg';
import keyIcon from '../images/testimonials-key-icon.png'; // Adjust path to your icon image

// Button component with CTA styles and hover effect
const TestimonialBox = ({ text, name }) => {
    return (
      <div className="testimonial-box">
        <div className="testimonial-content">
          <p className="testimonial-text">{text}</p>
        </div>
        <div className="testimonial-info">
          <img src={keyIcon} className="testimonial-icon" alt="Key Icon" />
          <p className="testimonial-name">{name}</p>
        </div>
      </div>
    );
  };
  
  // Main TestimonialsSlide component
  const TestimonialsSlide = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const testimonials = [
      {
        text:
          "I highly recommend Yes Financial for their exceptional assistance in securing the financing we needed. Their professionalism, responsiveness, and dedication made the entire process seamless and stress-free.",
        name: "Elizabeth",
      },
      {
        text:
          "I obtained an SBA loan thanks to YES FINANCIAL special thanks to Juan a Forgia who helped me and guided me from start to end. I am very grateful with Yes Financial team. Thanks to them I now have an SBA loan on my business which helped me grow in my industry.",
        name: "Gabriela",
      },
      {
        text:
          "Yes Financial is absolutely amazing. They had the right solution for my needs and everybody was just so supportive and available. We will definitely build a long and profitable business relationship.",
        name: "Gabriela",
      },
      // Add more testimonials as needed
    ];
  
    const handlePrev = () => {
      setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
    };
  
    const handleNext = () => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    };
  
    // Function to detect mobile screen size
    const isMobile = () => {
      return window.innerWidth <= 1024;
    };
  
    return (
      <div className="slide-container">
        <h2>Don’t Take Our Word For It.</h2>
  
        <div className="carousel">
          <img src={arrowIconleft} className="arrow left-arrow" onClick={handlePrev} alt="Previous" />
          <div className="reviews-container">
            {/* Display one or three testimonials based on mobile/desktop */}
            {testimonials
              .slice(currentIndex, currentIndex + (isMobile() ? 1 : 3))
              .map((testimonial, index) => (
                <TestimonialBox key={index} text={testimonial.text} name={testimonial.name} />
              ))}
          </div>
          <img src={arrowIcon} className="arrow right-arrow" onClick={handleNext} alt="Next" />
        </div>
  
        <div className="cta-button-container">
          <CtaButton />
        </div>
      </div>
    );
  };
  
  // Button component with CTA styles and hover effect
  const CtaButton = () => {
    return (
      <a href="/testimonials-page" className="cta-button">
        Read All Testimonials
      </a>
    );
  };
  
  export default TestimonialsSlide;