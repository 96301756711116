import React, { useState } from 'react';
import '../css/style.css'; // Ensure correct path to CSS
import arrowIcon from '../images/arrow_icon.svg'; // Make sure to adjust the path to your SVG file
import arrowIconleft from '../images/arrow_icon_left.svg';
import keyIcon from '../images/testimonials-key-icon.png'; // Adjust path to your icon image

const SBALoansSlide = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      text: "I highly recommend Yes Financial for their exceptional assistance in securing the financing we needed. Their professionalism, responsiveness, and dedication made the entire process seamless and stress-free.",
      name: "Elizabeth",
    },
    {
      text: "I obtained an SBA loan thanks to YES FINANCIAL special thanks to Juan a Forgia who helped me and guided me from start to end. I am very grateful with Yes Financial team. Thanks to them I now have an SBA loan on my business which helped me grow in my industry.",
      name: "Gabriela",
    },
    {
      text: "Yes Financial is absolutely amazing. They had the right solution for my needs and everybody was just so supportive and available. We will definitely build a long and profitable business relationship.",
      name: "Gabriela",
    },
    // Add more testimonials as needed
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  // Function to detect mobile screen size
  const isMobile = () => {
    return window.innerWidth <= 1024;
  };

  return (
    <div className="sba-loans-slide">
      {/* Hero Section */}
      <section className="hero-slide">
        <div className="hero-content">
          <h1>SBA LOANS</h1>
          <p>Empower Your Business with SBA 7(a) Loans</p>
        </div>
      </section>
      
      {/* Content Section */}
      <div className="content-section">
        <header className="content-header">
          <h1>Why Yes Financial Service?</h1>
        </header>
        <section className="content-body">
          <p>
            At Yes Financial Service, we redefine business loans as tools for thriving, not just surviving. Our expert team is dedicated to guiding you through the best SBA Loans and commercial loan programs to fuel your success. Partner with us to secure long-term financing and elevate your business to new heights. Let Yes Financial Service help you achieve your growth goals and transform your vision into reality.
          </p>
          <div className="info-boxes">
            <div className="info-box">
              <h2>Unlock Long-Term Financing with Ease</h2>
              <p>Yes Financial Service makes accessing SBA Loans easier than ever with new changes to SBA procedures. Enjoy flexible long-term financing options with terms up to 25 years, tailored to your business needs.</p>
            </div>
            <div className="info-box">
              <h2>Speedy Financing with Preferred Lender Access</h2>
              <p>Benefit from faster decision-making with Yes Financial Service. We partner with lenders in the SBA's Preferred Lender Program (PLP) to streamline your loan process and find the SBA program that best supports your business growth.</p>
            </div>
            <div className="info-box">
              <h2>Tailored Loan Solutions for Your Business</h2>
              <p>Share your business goals with Yes Financial Service, and we'll recommend the ideal SBA Loan program for you. We handle all the documentation and guide you through every step of the loan process, ensuring a smooth and efficient experience.</p>
            </div>
          </div>
        </section>
      </div>
      
      {/* Additional Section */}
      <section className="sba-values-slide">
        <div className="sba-values-top-left">
          <h2>SBA Loans</h2>
          <p>Unlock your growth potential with Yes Financial Service. Whether you're purchasing or renovating commercial real estate, acquiring a franchise, or expanding your facilities, our competitive SBA 7(a) loans offer flexible financing solutions tailored to your needs. Transform your vision into reality today!</p>
        </div>
        <div className="sba-values-box sba-values-top-right">
          <h2>Flexible Financing Solutions</h2>
          <p>At Yes Financial Service, our SBA Loans cover a range of needs including purchases of owner-occupied commercial real estate, business acquisitions, franchise investments, leasehold improvements, working capital, debt refinancing, and equipment purchases.</p>
        </div>
        <div className="sba-values-box sba-values-bottom-left">
          <h2>Revitalize Your Space</h2>
          <p>Transform an existing property into your ideal workspace with Yes Financial Service. Our SBA 7(a) loans offer competitive rates for acquiring and renovating commercial buildings, helping you set up operations efficiently.</p>
        </div>
        <div className="sba-values-box sba-values-bottom-right">
          <h2>Expand with Ease</h2>
          <p>Growing your business? Yes Financial Service provides SBA 7(a) loans to finance expansions, allowing you to add on to your current facilities and accommodate your evolving needs.</p>
        </div>
      </section>

      {/* Testimonials Section */}
      <div className="slide-container-custom">
        <h2>Real Expereince From Real Customers</h2>

        <div className="carousel-custom">
          <img src={arrowIconleft} className="arrow left-arrow-custom" onClick={handlePrev} alt="Previous" />
          <div className="reviews-container-custom">
            {testimonials
              .slice(currentIndex, currentIndex + (isMobile() ? 1 : 3))
              .map((testimonial, index) => (
                <div className="testimonial-box-custom" key={index}>
                  <div className="testimonial-content-custom">
                    <p className="testimonial-text-custom">{testimonial.text}</p>
                  </div>
                  <div className="testimonial-info-custom">
                    <img src={keyIcon} className="testimonial-icon-custom" alt="Key Icon" />
                    <p className="testimonial-name-custom">{testimonial.name}</p>
                  </div>
                </div>
              ))}
          </div>
          <img src={arrowIcon} className="arrow right-arrow-custom" onClick={handleNext} alt="Next" />
        </div>

        <div className="cta-button-container-custom">
          <a href="/testimonials-page" className="cta-button-custom">Read All Testimonials</a>
        </div>
      </div>
    </div>
  );
};

export default SBALoansSlide;