import React from 'react';

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="container">
        <div className="row">
          {/* About Us Text Section */}
          <div className="col-md-6">
            <h2>About Yes Financial Services</h2>
            <p>
              Yes Financial Service provides assistance and elbow grease to help its clients achieve success. The company takes pride in its humble beginnings and its self-proclaimed duty to help protect unwary borrowers from high-cost and risky non-traditional lending practices.
            </p>
            <p>
              Yes Financial Service distinguishes itself from online and non-traditional lenders by only offering SBA loan advisement and action. It is not a lending institution or a marketplace for business loans. The company works one-on-one with businesses and individuals seeking legitimate SBA-backed funding.
            </p>
            <a href="#" className="btn btn-primary">LEARN MORE</a>
          </div>

          {/* Boxes Section */}
          <div className="col-md-6">
            <div className="custom-boxes">
              {/* Box 1 */}
              <div className="custom-box">
                <h3>Our Mission</h3>
                <p>
                  Sint clita assueverit eu est, pri at facete putant. Ferri graece conclusionemque quo ne, te congue nostrum usu. Id qui praesent reformidans. Elit eripuit tincidunt cum et. Cu qui nostro labores, omnis graeci efficiantur eos ne traspt.
                </p>
              </div>

              {/* Box 2 */}
              <div className="custom-box">
                <h3>Our Vision</h3>
                <p>
                  Sint clita assueverit eu est, pri at facete putant. Ferri graece conclusionemque quo ne, te congue nostrum usu. Id qui praesent reformidans. Elit eripuit tincidunt cum et. Cu qui nostro labores, omnis graeci efficiantur eos ne traspt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;