import React from 'react';
import '../css/style.css'; // Make sure the path is correct

const ProgressBar = ({ steps, currentStep }) => {
  const progress = (currentStep / (steps.length - 1)) * 100;

  return (
    <div className="progress-bar-container">
      <div className="progress-bar-text">
        Step {currentStep + 1} of {steps.length}
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
